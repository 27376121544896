import React from 'react';

const clientImages = [
    { src: 'https://content.qeeb.in/coltec/home/clients/pepsi-co-logo.svg', alt: 'Pepsi Co Logo' },
    { src: 'https://content.qeeb.in/coltec/home/clients/new-york-life.svg', alt: 'New York Life Logo' },
    { src: 'https://content.qeeb.in/coltec/home/clients/ford-logo.svg', alt: 'Ford Logo' },
    { src: 'https://content.qeeb.in/coltec/home/clients/monash-university-logo.svg', alt: 'Monash University Logo' },
    { src: 'https://content.qeeb.in/coltec/home/clients/oracle-logo.svg', alt: 'Oracle Logo' },
    { src: 'https://content.qeeb.in/coltec/home/clients/johnson-and-johnson-logo.svg', alt: 'Johnson & Johnson Logo' },
    { src: 'https://content.qeeb.in/coltec/home/clients/cquniversity-logo.svg', alt: 'CQUniversity Logo' },
    { src: 'https://content.qeeb.in/coltec/home/clients/avondale-university-college-logo.svg', alt: 'Avondale University College Logo' },
    { src: 'https://content.qeeb.in/coltec/home/clients/walmart-logo.svg', alt: 'Walmart Logo' },
    { src: 'https://content.qeeb.in/coltec/home/clients/the-university-of-melbourne-logo.svg', alt: 'The University of Melbourne Logo' },
    { src: 'https://content.qeeb.in/coltec/home/clients/rkt-big-logo.svg', alt: 'RKT Logo' },
    { src: 'https://content.qeeb.in/coltec/home/clients/pepsi-co-logo.svg', alt: 'Pepsi Co Logo' },
    { src: 'https://content.qeeb.in/coltec/home/clients/new-york-life.svg', alt: 'New York Life Logo' },
    { src: 'https://content.qeeb.in/coltec/home/clients/ford-logo.svg', alt: 'Ford Logo' },
    { src: 'https://content.qeeb.in/coltec/home/clients/monash-university-logo.svg', alt: 'Monash University Logo' },
    { src: 'https://content.qeeb.in/coltec/home/clients/oracle-logo.svg', alt: 'Oracle Logo' },
];

const Clients = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto px-6 lg:px-8 lg:py-14 py-10">
        <div className="lg:text-center">
          <h3 className="md:text-[40px] text-[28px] font-normal leading-8 mb-8">Our Clients</h3>
        </div>
        <div>
          <div className="overflow-hidden">
            <div className="relative flex justify-between">
              <div className="contents">
                <div className="slide-animation-logos flex">
                  {clientImages.map((client, index) => (
                    <img
                      key={index}
                      src={client.src}
                      alt={client.alt}
                      loading="lazy" // Enable lazy loading
                      className="h-20 w-32 mx-4"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Clients;
