import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SEO from "../components/SEO";
import ScrollToTopButton from "../components/ScrollTop";

const CookiesPolicy = () => {
  return (
    <>
      <div className="about-us-page">
        <SEO
          title="Cookies Policy | Coltec Global"
          description="Learn about how Coltec Global uses cookies to enhance your browsing experience and improve our services."
          keywords="Cookies Policy, Coltec Global, Website Cookies, Data Protection"
          ogTitle="Cookies Policy | Coltec Global"
          ogDescription="Understand Coltec Global's use of cookies and how you can manage your preferences."
          ogUrl="https://coltec-global.com/cookies-policy"
          ogType="website"
        />
        <Header />
        <div className="max-w-7xl px-5 lg:px-8 m-auto mx-auto mt-24 pb-12">
          <main className="p-8 bg-white shadow-lg border rounded-2xl text-[15px]">
            <h1 className="text-3xl font-bold mb-6">Cookies Policy</h1>
            <p>
              <strong>Effective Date:</strong> January 1, 2025
            </p>
            <p>
              At Coltec Global, accessible at{" "}
              <a href="https://www.coltec-global.com" className="text-blue-500">
                www.coltec-global.com
              </a>
              , we use cookies and similar technologies to enhance your browsing
              experience, analyze website traffic, and improve our services.
              This Cookies Policy explains what cookies are, how we use them,
              and how you can manage your preferences.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">
              1. What Are Cookies?
            </h2>
            <p>
              Cookies are small text files stored on your device (computer,
              smartphone, or tablet) when you visit a website. They help
              websites recognize your device and remember information about your
              visit, such as your preferences or login status.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">
              2. Types of Cookies We Use
            </h2>
            <ul className="list-disc pl-5">
              <li className="leading-[1.8]">
                <strong>Essential Cookies:</strong> Necessary for the website to
                function properly. Without them, some features may not work as
                intended.
              </li>
              <li className="leading-[1.8]">
                <strong>Analytics and Performance Cookies:</strong> Collect
                information about how visitors use our website, such as which
                pages are visited most often.
              </li>
              <li className="leading-[1.8]">
                <strong>Functionality Cookies:</strong> Remember your
                preferences and settings, such as language selection or login
                details.
              </li>
              <li className="leading-[1.8]">
                <strong>Marketing and Advertising Cookies:</strong> Deliver
                targeted advertisements based on your browsing behavior and
                interests.
              </li>
              <li className="leading-[1.8]">
                <strong>Third-Party Cookies:</strong> Set by third-party
                services we use, such as social media platforms, payment
                processors, or embedded content providers.
              </li>
            </ul>

            <h2 className="text-xl font-semibold mt-8 mb-4">
              3. Why Do We Use Cookies?
            </h2>
            <ul className="list-disc pl-5">
              <li className="leading-[1.8]">
                To ensure our website functions as expected.
              </li>
              <li className="leading-[1.8]">
                To remember your preferences and settings.
              </li>
              <li className="leading-[1.8]">
                To analyze website traffic and user behavior for optimization.
              </li>
              <li className="leading-[1.8]">
                To display personalized content and relevant advertisements.
              </li>
              <li className="leading-[1.8]">
                To maintain security and prevent fraudulent activity.
              </li>
            </ul>

            <h2 className="text-xl font-semibold mt-8 mb-4">
              4. Managing Your Cookies Preferences
            </h2>
            <p>
              You can manage or disable cookies through your browser settings.
              Most browsers allow you to:
            </p>
            <ul className="list-disc pl-5">
              <li className="leading-[1.8]">Block or delete all cookies.</li>
              <li className="leading-[1.8]">
                Set preferences for specific websites.
              </li>
              <li className="leading-[1.8]">
                Receive notifications when a website sets cookies.
              </li>
            </ul>
            <p>
              For more information on managing cookies, refer to the help
              documentation of your browser:
            </p>
            <ul className="list-disc pl-5">
              <li className="leading-[1.8]">Google Chrome: Manage cookies</li>
              <li className="leading-[1.8]">
                Mozilla Firefox: Cookies settings
              </li>
              <li className="leading-[1.8]">Microsoft Edge: Clear cookies</li>
              <li className="leading-[1.8]">Safari: Manage cookies</li>
            </ul>

            <h2 className="text-xl font-semibold mt-8 mb-4">
              5. Cookies We Use
            </h2>
            <table className="table-auto w-full border-collapse border border-gray-300 mt-4 rounded-lg">
              <thead>
                <tr>
                  <th className="border border-gray-300 px-4 py-2">
                    Cookie Type
                  </th>
                  <th className="border border-gray-300 px-4 py-2">Purpose</th>
                  <th className="border border-gray-300 px-4 py-2">Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-300 px-4 py-2">
                    Essential Cookies
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    To enable website functionality.
                  </td>
                  <td className="border border-gray-300 px-4 py-2">Session</td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-2">
                    Analytics Cookies
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    To track website performance and user behavior.
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    Up to 2 years
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-2">
                    Functionality Cookies
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    To remember preferences and settings.
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    Up to 1 year
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-300 px-4 py-2">
                    Marketing Cookies
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    To provide personalized advertisements.
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    Up to 90 days
                  </td>
                </tr>
              </tbody>
            </table>

            <h2 className="text-xl font-semibold mt-8 mb-4">
              6. Third-Party Cookies
            </h2>
            <p>
              We may use third-party services that set cookies on your device,
              such as:
            </p>
            <ul className="list-disc pl-5">
              <li className="leading-[1.8]">
                Google Analytics: For website traffic analysis.
              </li>
              <li className="leading-[1.8]">
                Facebook Pixel: For targeted advertising on social media.
              </li>
              <li className="leading-[1.8]">
                YouTube: For embedding video content.
              </li>
            </ul>
            <p>
              These third parties have their own privacy and cookies policies,
              which we encourage you to review.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">
              7. Consent to Use Cookies
            </h2>
            <p>
              When you first visit our website, you will see a cookies banner
              asking for your consent to use non-essential cookies. You can
              manage your preferences at any time by clicking the “Cookies
              Settings” link available on our website footer.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">
              8. Updates to This Policy
            </h2>
            <p>
              We may update this Cookies Policy to reflect changes in our use of
              cookies or compliance with legal requirements. Changes will be
              posted on this page with an updated 'Effective Date'.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">9. Contact Us</h2>
            <p>
              If you have questions about our use of cookies, please contact us:
            </p>
            <address>
              Coltec Global <br />
              Survey No.75/A, Gagan Pahad, <br />
              Rajendra Nagar Municipality, <br />
              Ranga Reddy District, TS, INDIA <br />
              Email:{" "}
              <a href="mailto:info@coltec-global.com" className="text-blue-500">
                info@coltec-global.com
              </a>
            </address>
          </main>
        </div>
        <Footer />
        <ScrollToTopButton />
      </div>
    </>
  );
};

export default CookiesPolicy;
