import React from "react";
import { Link } from "react-router-dom";
import { blogData } from "../utils/blogData";
import { useLocation } from "react-router-dom";

const Blogs = () => {
  const location = useLocation();

  // Sort blogData by date (newest first)
  const sortedBlogData = [...blogData].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  // Find the 'section' blog (isSection === true) and filter it out from normal blogs
  const sectionBlog = sortedBlogData.filter((blog) => blog.isSection === true);
  const normalBlogs = sortedBlogData.filter((blog) => !blog.isSection);

  return (
    <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl mx-auto">
      {/* 
        1. Render the special section blog (isSection: true) 
           — Only if it exists
      */}
      {sectionBlog && (
        <div className="mb-14">
          <h1 className="text-[26px] font-semibold mb-6 text-black">
            Smart White Boards vs. Traditional Teaching Tools
          </h1>
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            {sectionBlog.map((blog) => (
              <Link key={blog.id} to={`/blogs/${blog.slug}`}>
                <div className="rounded-xl bg-white shadow-lg hover:shadow-xl transition transform hover:-translate-y-2">
                  <img
                    src={blog.image}
                    alt={blog.alt}
                    className="rounded-t-lg h-[200px] w-full object-cover"
                  />
                  <div className="p-6">
                    <h2 className="text-sm font-semibold mb-3">{blog.title}</h2>
                    <p className="text-gray-500 text-xs line-clamp-2">
                      {blog.metaDescription}
                    </p>
                    <div className="flex justify-between mt-3 items-center">
                      <p className="text-gray-800 text-sm">
                        {blog.author_name}
                      </p>
                      <p className="text-gray-400 text-sm">{blog.date}</p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}

      {/* 
        2. Hero Blog: The first item (now from normalBlogs) 
           — If you want the hero blog logic to remain the same
      */}
      {normalBlogs.length > 0 && (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mb-24 blog-one">
          <img
            src={normalBlogs[0].image}
            alt={normalBlogs[0].alt}
            className="w-full md:h-[340px] lg:object-cover rounded-xl shadow-lg"
          />
          <div>
            {location.pathname === "/blogs" ? (
              <h2 className="text-[24px] font-semibold mb-2 text-black">
                {normalBlogs[0].title}
              </h2>
            ) : (
              <h3 className="text-[24px] font-medium mb-4 text-black">
                {normalBlogs[0].title}
              </h3>
            )}
            <p className="mb-4">
              <span className="text-gray-900 font-medium">
                {normalBlogs[0].author_name}
              </span>
            </p>
            <p className="text-black font-normal text-[15px] mb-6">
              {normalBlogs[0].metaDescription}
            </p>
            <p className="text-[#4E4E4E] font-normal text-sm mb-6">
              {normalBlogs[0].date}
            </p>
            <Link
              to={`/blogs/${normalBlogs[0].slug}`}
              className="bg-white border focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white inline-block lg:px-6 px-6 py-2 shadow-sm text-sm"
            >
              Read More <span className="ml-3">→</span>
            </Link>
          </div>
        </div>
      )}

      {/* 
        3. The rest of the blog cards
           — Slicing from index 1 onward since the hero blog used index 0
      */}
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
        {normalBlogs.slice(1).map((blog) => (
          <Link key={blog.id} to={`/blogs/${blog.slug}`}>
            <div className="rounded-xl bg-white shadow-lg hover:shadow-xl transition transform hover:-translate-y-2">
              <img
                src={blog.image}
                alt={blog.alt}
                className="rounded-t-lg h-[200px] w-full object-cover"
              />
              <div className="p-6">
                <h2 className="text-sm font-semibold mb-3">{blog.title}</h2>
                <p className="text-gray-500 text-xs line-clamp-2">
                  {blog.metaDescription}
                </p>
                <div className="flex justify-between mt-3 items-center">
                  <p className="text-gray-800 text-sm">{blog.author_name}</p>
                  <p className="text-gray-400 text-sm">{blog.date}</p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
