const expoData = [
  {
    id: 1,
    title: "ET Techx Expo - 2024",
    images: [
      {
        src: "https://content.qeeb.in/coltec/media/et-expo-team-4.jpeg",
        alt: "Coltec booth showcasing Rise and Inertia Series Intelligent Interactive Flat Panels for education and corporate solutions.",
        class: "row-span-2 col-span-2 h-[405px]",
      },
      {
        src: "https://content.qeeb.in/coltec/media/et-expo-team-2.jpg",
        alt: "Coltec showcasing Rise 75'' Interactive Flat Panel and Inertia Series 1.25mm LED display for advanced classroom and corporate applications.",
        class: "col-span-2 h-[190px]",
      },
      {
        src: "https://content.qeeb.in/coltec/media/et-expo-team-3.jpeg",
        alt: "Coltec team at ET TECHX 2024, showcasing innovative education and corporate technology solutions at Hitec Hyderabad.",
        class: "col-span-1 h-[190px]",
      },
      {
        src: "https://content.qeeb.in/coltec/media/et-expo-team-5.jpeg",
        alt: "Coltec booth showcasing Rise and Inertia Series Intelligent Interactive Flat Panels for education and corporate solutions.",
        class: "col-span-1 h-[190px]",
      },
      {
        src: "https://content.qeeb.in/coltec/media/et-expo-team-1.jpeg",
        alt: "Coltec team proudly presenting interactive flat panel solutions at ET TECHX 2024, redefining educational and corporate innovation.",
        class: "col-span-2 h-[190px]",
      },
    ],
    description:
      "At India’s second-largest education expo, COLTEC proudly presented QEEB, bridging world-class teaching resources with cutting-edge technology. A showcase where education’s next chapter takes center stage!",
  },
  {
    id: 2,
    title: "Rifaah Expo - 2024",
    images: [
      {
        src: "https://content.qeeb.in/coltec/media/rifaah-expo-team-3.jpg",
        alt: "Coltec showcasing world's 1st intelligent classroom technology featuring interactive flat panels at Rifaah Expo 2024.",
        class: "row-span-2 col-span-3 h-[400px]",
      },
      {
        src: "https://content.qeeb.in/coltec/media/rifaah-expo-team-4.jpeg",
        alt: "Coltec team receiving an award at Rifaa Expo 2024 hosted by the Rifaa Chamber of Commerce and Industry, Hyderabad, celebrating excellence in innovation and technology.",
        class: "col-span-1 h-[175px]",
      },
      {
        src: "https://content.qeeb.in/coltec/media/rifaah-expo-team-2.jpeg",
        alt: "Coltec team showcasing the advanced features of their Interactive Flat Panel at Rifaa Expo 2024, engaging visitors with live demonstrations of cutting-edge educational and business solutions.",
        class: "col-span-1 h-[175px]",
      },
      {
        src: "https://content.qeeb.in/coltec/media/rifaah-expo-team-1.jpeg",
        alt: "Coltec team presenting the revolutionary Interactive Flat Panel technology at Rifaa Expo 2024, demonstrating its applications in education and healthcare with an engaging anatomical display.",
        class: "col-span-2 h-[200px]",
      },
    ],
    description:
      "COLTEC proudly showcased its groundbreaking solutions at the Rifah Expo, connecting with educators and tech enthusiasts to redefine the future of learning and collaboration. A platform where innovation met inspiration!",
  },
];

export default expoData;
