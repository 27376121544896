import React from "react";
import { Link } from "react-router-dom";
import { blogData } from "../../utils/blogData";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CTA from "../../components/CTA";
import ScrollToTopButton from "../../components/ScrollTop";
import SEO from "../../components/SEO";
import WhatsAppMsg from "../../components/WhatsApp";

const InteractiveFlatPanel = () => {
  // Filter only "Interactive Flat Panel" blogs
  const filteredData = blogData.filter(
    (blog) => blog.category === "Interactive Flat Panel"
  );
   const sortedBlogData = [...filteredData].sort((a, b) => new Date(b.date) - new Date(a.date));
  // If there are no blog posts in this category, you could handle that gracefully.
  if (!filteredData.length) {
    return (
      <div className="px-6 lg:px-8 py-10 max-w-7xl mx-auto">
        <h2 className="text-xl font-semibold">No blogs found for this category.</h2>
      </div>
    );
  }

  return (
    <>
    <div className="blogs-page">
        <SEO
          title="Educational & Corporate Technology Insights | Coltec Blogs"
          description="Explore the latest trends, tips, and innovations in educational and corporate technology. Learn how Coltec's interactive solutions are transforming classrooms."
          keywords="Interactive flat panels, Education technology blogs, Corporate technology insights, Classroom collaboration tools, Digital whiteboards, Smart boards, Interactive displays, Touch screen panels for business, Modern learning technology, Workplace innovation blogs, Technology transformation, EdTech, Corporate collaboration solutions"
          ogTitle="Educational & Corporate Technology Innovations | Coltec Blogs"
          ogDescription="Stay ahead with Coltec's blogs on cutting-edge educational and corporate technology. Discover insights, tips, and solutions for modern learning and business environments."
          ogImage="https://content.qeeb.in/coltec/coltec-blog-banner.jpg"
          ogUrl="https://coltec-global.com/blogs"
          canonicalUrl="https://coltec-global.com/blogs"
          ogImageAlt="Coltec Blogs - Technology Innovations in Education and Corporate"
          ogSiteName="Coltec Global"
          ogType="website"
        />
        <Header />
        <div className="mt-16">
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl mx-auto">
      {/* -- Hero Blog -- */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mb-24 blog-one">
        <img
          src={sortedBlogData[0].image}
          alt={sortedBlogData[0].alt}
          className="w-full md:h-[340px] lg:object-cover rounded-xl"
        />
        <div>
          <h3 className="text-[24px] font-medium mb-4 text-black">
            {sortedBlogData[0].title}
          </h3>
          <p className="mb-4">
            <span className="text-gray-900 font-medium">
              {sortedBlogData[0].author_name}
            </span>
          </p>
          <p className="text-black font-normal text-[15px] mb-6">
            {sortedBlogData[0].metaDescription}
          </p>
          <p className="text-[#4E4E4E] font-normal text-sm mb-6">
            {sortedBlogData[0].date}
          </p>
          <Link
            to={`/blogs/${sortedBlogData[0].slug}`}
            className="bg-white border inline-block lg:px-6 px-6 py-2 shadow-sm text-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
          >
            Read More<span className="ml-3">→</span>
          </Link>
        </div>
      </div>

      {/* -- List of Other Blog Cards -- */}
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
        {sortedBlogData.slice(1).map((blog) => (
          <Link key={blog.id} to={`/blogs/${blog.slug}`}>
            <div className="rounded-xl bg-white shadow-lg hover:shadow-xl transition transform hover:-translate-y-2">
              <img
                src={blog.image}
                alt={blog.alt}
                className="rounded-t-lg h-[200px] w-full object-cover"
              />
              <div className="p-6">
                <h2 className="text-sm font-semibold mb-3">{blog.title}</h2>
                <p className="text-gray-500 text-xs line-clamp-2">
                  {blog.metaDescription}
                </p>
                <div className="flex justify-between mt-3 items-center">
                  <p className="text-gray-800 text-sm">{blog.author_name}</p>
                  <p className="text-gray-400 text-sm">{blog.date}</p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
        </div>
        <CTA />
        <Footer />
        <ScrollToTopButton />
        <WhatsAppMsg />
      </div>
    </>
    
  );
};

export default InteractiveFlatPanel;
