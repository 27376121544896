// OurProducts.jsx
import React from "react";
import { Link } from "react-router-dom";
const products = [
  {
    id: 1,
    src: "https://content.qeeb.in/coltec/home/products/product-1.webp",
    alt: "Coltec Interactive Flat Panel",
    name: "Interactive Flat Panel",
    span: "col-span-2 row-span-2",
    height: "md:h-[500px]",
    url: "/",
  },
  {
    id: 2,
    src: "https://content.qeeb.in/coltec/home/products/product-2.webp",
    alt: "Coltec Room Scheduler",
    name: "Room Scheduler",
    span: "col-span-2 row-span-1",
    height: "md:h-[240px]",
    url: "/",
  },
  {
    id: 3,
    src: "https://content.qeeb.in/coltec/home/products/product-8.webp",
    alt: "Coltec Tablet",
    name: "Tablet",
    span: "col-span-2 row-span-1",
    height: "md:h-[240px]",
    url: "/",
  },
  {
    id: 4,
    src: "https://content.qeeb.in/coltec/home/products/product-4.webp",
    alt: "Coltec All-in-One Touch Monitor",
    name: "All-in-One Touch Monitor",
    span: "col-span-2 row-span-1",
    height: "md:h-[260px]",
    url: "/",
  },
  {
    id: 5,
    src: "https://content.qeeb.in/coltec/home/products/product-7.webp",
    alt: "Coltec Kiosks",
    name: "Kiosks",
    span: "col-span-2 row-span-2",
    height: "md:h-[540px] h-[200px]",
    url: "/",
  },
  {
    id: 6,
    src: "https://content.qeeb.in/coltec/home/products/product-6.webp",
    alt: "Coltec Video Wall",
    name: "Video Wall",
    span: "col-span-2 row-span-1",
    height: "md:h-[260px]",
    url: "/",
  },
  {
    id: 7,
    src: "https://content.qeeb.in/coltec/home/products/product-3.webp",
    alt: "Coltec Commercial Display",
    name: "Commercial Display",
    span: "col-span-2 row-span-1",
    height: "md:h-[300px] h-[200px]",
    url: "/",
  },
  {
    id: 8,
    src: "https://content.qeeb.in/coltec/home/products/product-5.webp",
    alt: "Coltec Active LED",
    name: "Active LED",
    span: "col-span-2 row-span-1",
    height: "md:h-[300px]",
    url: "/",
  },
];

const OurProducts = () => {
  return (
    <>
      <div className="bg-gray-50">
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
          <h3 className="md:text-[40px] text-[28px] leading-9 font-normal lg:text-center md:mb-8 mb-5">
            Our Products
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {products.map((product) => (
              <Link
                key={product.id}
                to={product.url}
                className={`flex relative overflow-hidden rounded-lg ${product.span} ${product.height}`}
              >
                <img
                  src={product.src}
                  alt={product.alt}
                  loading="lazy"
                  className="w-full h-full rounded-lg transition-all object-cover hover:scale-110 ease-in duration-700"
                />
                <p className="text-white font-[500] p-[2px_50px_2px_14px] leading-[24px] absolute bottom-[12px] text-[16px] bg-gradient-to-r from-gray-900 via-gray-900 bg-opacity-80">
                  {product.name}
                </p>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurProducts;
