import React, { useEffect, useRef, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { blogData } from "../utils/blogData";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CTA from "../components/CTA";
import ScrollToTopButton from "../components/ScrollTop";
import SEO from "../components/SEO";
import WhatsAppMsg from "../components/WhatsApp";

const BlogDetails = () => {
  const { title } = useParams();
  const blogIndex = blogData.findIndex((b) => b.slug === title);
  const blog = blogData[blogIndex];

  // Determine previous and next blogs
  const prevBlog = blogIndex > 0 ? blogData[blogIndex - 1] : null;
  const nextBlog =
    blogIndex < blogData.length - 1 ? blogData[blogIndex + 1] : null;

  // State for controlling the popup visibility
  const [showSuggestion, setShowSuggestion] = useState(true);

  // We will track intersection on the CTA section
  const ctaRef = useRef(null);

  // Intersection Observer to detect when CTA is visible
  useEffect(() => {
    // Capture the current ref value in a local variable
    const targetNode = ctaRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowSuggestion(entry.isIntersecting);
      },
      { threshold: 0.8 }
    );

    // Observe only if targetNode exists
    if (targetNode) {
      observer.observe(targetNode);
    }

    // Cleanup uses the *same* DOM node
    return () => {
      if (targetNode) {
        observer.unobserve(targetNode);
      }
    };
  }, []);

  // Function to get the latest two blogs
  const getLatestTwoBlogs = () => {
    // Clone the blogData array to avoid mutating the original
    const sortedBlogs = [...blogData].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA; // Descending order
    });
    return sortedBlogs.slice(0, 2); // Get the first two blogs
  };

  const latestTwoBlogs = getLatestTwoBlogs();

  return (
    <>
      <div className="blogs-page">
        {/* If the blog is valid, apply SEO meta tags */}
        {blog && (
          <SEO
            title={`${blog.title} | Coltec Global`}
            description={blog.metaDescription}
            keywords={blog.keywords}
            ogTitle={blog.title}
            ogDescription={blog.metaDescription}
            ogImage={blog.image}
            ogUrl={`https://coltec-global.com/blogs/${blog.slug}`}
            canonicalUrl={`https://coltec-global.com/blogs/${blog.slug}`}
            ogSiteName="Coltec Global"
            ogType="website"
          />
        )}
        <Header />
        <div className="relative">
          <div className="mx-auto max-w-7xl px-6 pt-24 pb-12 lg:px-8 md:pt-28 md:pb-14">
            <div className="grid grid-cols-12 xl:gap-10 gap-6 min-h-screen">
              {blog ? (
                <>
                  <div className="xl:col-span-8 col-span-full relative">
                    <div className="mb-12 relative">
                      <Link to="/blogs">
                        <img
                          alt="Blogs Arrow"
                          src="https://content.qeeb.in/coltec/arrow-black.svg"
                          className="w-6 absolute top-[-24px] xl:top-0 xl:left-[-30px] xl:pt-2.5"
                        />
                      </Link>
                      <div className="flex space-x-2">
                        <h1 className="md:text-[30px] text-[26px] font-semibold mb-2.5 max-w-2xl">
                          {blog.title}
                        </h1>
                      </div>
                      <div className="flex items-center">
                        <p className="font-medium text-sm mr-3">
                          <span className="text-gray-900">
                            {blog.author_name}
                          </span>
                        </p>
                        <p className="text-gray-500">
                          <span className="text-sm">{blog.date}</span>
                        </p>
                        <div className="md:block hidden">
                          <div className="flex justify-start items-center ml-3">
                            <div>
                              <img
                                alt="Category"
                                src="https://content.qeeb.in/coltec/category-icon.svg"
                                className="h-5"
                              />
                            </div>
                            <Link
                              to={blog.category_url}
                              className="bg-white border border-[#242424] ml-3 text-[#000] hover:text-[#242424] py-1 px-3 rounded-full text-sm"
                            >
                              {blog.category}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="md:hidden block">
                        <div className="flex justify-start items-center mt-3">
                          <div>
                            <img
                              alt="Category"
                              src="https://content.qeeb.in/coltec/category-icon.svg"
                              className="h-5"
                            />
                          </div>
                          <Link
                            to={blog.category_url}
                            className="bg-white border border-[#242424] ml-3 text-[#000] hover:text-[#242424] py-1 px-3 rounded-full text-sm"
                          >
                            {blog.category}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <img
                      alt={blog.alt}
                      src={blog.image}
                      className="rounded-lg w-full mb-6"
                    />
                    <div
                      className="prose mt-12"
                      dangerouslySetInnerHTML={{ __html: blog.content }}
                    ></div>
                  </div>
                  <div className="xl:col-span-4 col-span-full">
                    <div className="sticky top-[18rem]">
                      {/* Latest Two Blogs */}
                      <h2 className="font-medium text-xl mb-3">Latest Blogs</h2>
                      <div className="grid grid-cols-3 xl:block">
                        {latestTwoBlogs.map((latestBlog) => (
                          <div
                            key={latestBlog.id}
                            className="flex space-x-4 mb-6"
                          >
                            <div className="shrink-0">
                              <img
                                alt={latestBlog.alt}
                                src={latestBlog.image}
                                className="w-[150px] rounded-md h-[90px]"
                              />
                            </div>
                            <div>
                              <Link to={`/blogs/${latestBlog.slug}`}>
                                <h3 className="text-sm font-medium hover:underline">
                                  {latestBlog.title}
                                </h3>
                              </Link>
                            </div>
                          </div>
                        ))}
                      </div>
                      <h2 className="font-medium text-xl mt-8 mb-3">
                        Blog Categories
                      </h2>
                      <div className="flex flex-wrap gap-3">
                        <Link to="/blogs/interactive-flat-panel">
                          <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                            Interactive Flat Panel
                          </div>
                        </Link>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          Classroom Platform
                        </div>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          Collaboration
                        </div>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          Conferencing
                        </div>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          Tablet
                        </div>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          Room Scheduler
                        </div>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          Kiosks
                        </div>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          Video Wall
                        </div>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          Active LED
                        </div>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          All-in-One Touch Monitor
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <p>Blog not found</p>
              )}
            </div>
          </div>

          {/* 
          The popup is shown only if:
          1. CTA is visible (showSuggestion === true)
          2. There is either a previous blog or next blog
        */}
          {showSuggestion && (prevBlog || nextBlog) && (
            <div className="px-6 lg:px-8 max-w-7xl mx-auto pb-14">
              <ul
                className={`xl:flex space-y-4 xl:divide-x-2 xl:space-y-0 ${
                  prevBlog && nextBlog
                    ? "justify-between"
                    : prevBlog
                    ? "justify-start"
                    : nextBlog
                    ? "justify-end"
                    : ""
                }`}
              >
                {prevBlog && (
                  <Link to={`/blogs/${prevBlog.slug}`} className="group">
                    <li className="flex justify-start items-center">
                      <div className="mr-3">
                        <img
                          alt="blogs-arrow"
                          className="h-4"
                          src="https://content.qeeb.in/coltec/blogs-arrow-l.svg"
                        />
                      </div>
                      <div>
                        <p className="text-base font-light text-gray-700">
                          Previous
                        </p>
                        <p className="xl:w-[500px] text-base">
                          {prevBlog.title}
                        </p>
                      </div>
                    </li>
                  </Link>
                )}
                {nextBlog && (
                  <Link to={`/blogs/${nextBlog.slug}`} className="group pl-8">
                    <li className="flex justify-end items-center">
                      <div className="text-right">
                        <p className="text-base font-light text-gray-700">
                          Next
                        </p>
                        <p className="xl:w-[500px] text-base">
                          {nextBlog.title}
                        </p>
                      </div>
                      <div className="ml-3">
                        <img
                          alt="blogs-arrow"
                          className="h-4"
                          src="https://content.qeeb.in/coltec/blogs-arrow-r.svg"
                        />
                      </div>
                    </li>
                  </Link>
                )}
              </ul>
            </div>
          )}
        </div>

        {/* This div is used for intersection observer */}
        <div>
          <CTA />
        </div>
        <Footer />
        <ScrollToTopButton />
        <WhatsAppMsg />
      </div>
    </>
  );
};

export default BlogDetails;
