import React, { useState, useEffect } from "react";

const Banner = ({
  imgSrc,
  altText,
  headerText,
  heading,
  paragraphText,
  paragraphTextTwo,
  paragraphTextThree,
  opacityClasses = "opacity-layer absolute inset-0 w-full h-full bg-neutral-900/20 z-0",
  positionClasses = "absolute bottom-20 2xl:left-8", // Default value
  headerTextClasses = "text-xl font-semibold text-white lg:text-5xl 2xl:drop-shadow-lg",
  headClasses = "text-xl inline-flex font-semibold text-white lg:text-5xl 2xl:drop-shadow-lg",
  paragraphTextClasses = "mt-2 text-lg leading-7 text-white max-w-lg",
  paragraphTextThreeClasses = "text-[13px]",
  // Dynamic height classes based on screen size
  bannerHeight = "h-dvh",
  imgHeight = "h-full w-full",
}) => {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        // Adjust this value as needed
        setIsAnimated(true);
      } else {
        setIsAnimated(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={`relative isolate overflow-hidden ${bannerHeight} pt-14`}>
      <img
        alt={altText}
        src={imgSrc}
        width="100%"
        height="auto"
        className={`absolute inset-0 -z-10 object-cover ${imgHeight}`}
      />
      <div className={opacityClasses}></div>
      <div className={positionClasses}>
        <div className="text-left px-6 lg:px-8 max-w-7xl mx-auto">
          <div
            className={`inline-flex flex-col ${isAnimated ? "fadeInUp" : ""}`}
          >
            {headerText && (
              <span className={headerTextClasses}>{headerText}</span>
            )}
            <h1 className={headClasses}>{heading}</h1>
            {paragraphText && (
              <p className={paragraphTextClasses}>{paragraphText}</p>
            )}
            {paragraphTextTwo && (
              <p className={`${paragraphTextClasses} mt-4 `}>
                {paragraphTextTwo}
              </p>
            )}
            {paragraphTextThree && (
              <p className={`${paragraphTextThreeClasses} mt-4 `}>
                {paragraphTextThree}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
