import React, { useState, useEffect } from "react";
import ScrollToTopButton from "../../components/ScrollTop";
import SEO from "../../components/SEO";
import WhatsAppMsg from "../../components/WhatsApp";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../components/Footer";

const InteractiveFlatPanel = () => {
  const location = useLocation();
  const [isFixed, setIsFixed] = useState(false);
  const [currentSection, setCurrentSection] = useState(1); // Track the current section

  useEffect(() => {
    const handleScroll = () => {
      setIsFixed(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  // Function to scroll to the next section
  const scrollToNextSection = () => {
    const nextSection = document.getElementById(`section${currentSection + 1}`);
    if (nextSection) {
      window.scrollTo({
        top: nextSection.offsetTop,
        behavior: "smooth",
      });
      setCurrentSection(currentSection + 1); // Update the current section
    }
  };

  return (
    <>
      <div>
        <SEO
          title="Evoke Series Interactive Flat Panels – Ignite Meaningful Interaction | Coltec Global"
          description="Unleash creativity with Evoke Series interactive flat panels from Coltec Global—intuitive, vibrant displays that bring every lesson, meeting, and idea to life."
          keywords="Evoke Series, Interactive Flat Panel, Smart Classroom Display, Touch Screen Panel for Education, Digital Whiteboard, Interactive Display for Schools, 4K Ultra HD Interactive Panel, Interactive Touch Screen for Business, Interactive Flat Panel Display, Smart Board for Classrooms, Education Technology Solutions, Best Interactive Flat Panel for Schools, Touch Screen Display for Collaborative Learning, Interactive Smart Board for Online Teaching, High-Resolution Interactive Display for Business Meetings, Top Interactive Flat Panels for Classrooms, Multi-Touch Capability Interactive Panel, Zero Bonding Touch Technology Display, Integrated Software Solutions for Teaching, Interactive LED Panel with Low Power Consumption, Durable Interactive Display with Anti-Glare Technology, Interactive Panels for Hybrid Classrooms, Interactive Whiteboard for Conference Rooms, Digital Signage and Interactive Displays for Offices, Interactive Flat Panels for Remote Learning, Educational Interactive Touch Screen Panels, Interactive Flat Panel Supplier in India, Smart Classroom Displays for Indian Schools"
          ogTitle="Evoke Series Interactive Flat Panels – Ignite Meaningful Interaction | Coltec Global"
          ogDescription="Unleash creativity with Evoke Series interactive flat panels from Coltec Global—intuitive, vibrant displays that bring every lesson, meeting, and idea to life."
          ogImage="https://content.qeeb.in/coltec/products/evoke-banner-image.jpg"
          ogUrl="https://coltec-global.com/products/interactive-flat-panel/evoke-series"
          canonicalUrl="https://coltec-global.com/products/interactive-flat-panel/evoke-series"
          ogImageAlt="Evoke Series Interactive Flat Panel by Coltec"
          ogSiteName="Coltec Global"
          ogType="website"
        />
        <header
          className={`inset-x-0 top-0 z-50 border-white/30 bg-gradient-radial backdrop-blur-md border-b ${
            isFixed ? "fixed fadeInDown bg-white fixed-nav" : ""
          }`}
        >
          <nav className="flex items-center lg:justify-center justify-between px-5 max-w-[1400px] m-auto">
            <div className="flex lg:flex-1 py-5">
              <Link to="/">
                <img
                  alt="Coltec Logo"
                  width="130"
                  height="28"
                  src={
                    isFixed
                      ? "https://content.qeeb.in/coltec/coltec-logo-black.svg"
                      : "https://content.qeeb.in/coltec/coltec-logo-black.svg"
                  }
                  className="md:h-7"
                />
              </Link>
            </div>

            <div className="lg:flex lg:gap-x-12">
              {isFixed && (
                <Link
                  to="/"
                  className="text-sm transition-all font-medium leading-6 text-gray-950"
                >
                  Visit our Website
                </Link>
              )}
            </div>
          </nav>
        </header>
        <div className="relative flex flex-col">
          <div className="max-w-6xl pt-32 mx-auto px-5">
            <div className="flex justify-center items-center flex-col">
              <h1 className="font-bebas text-[#ED0000] text-center font-bebas text-[60px] font-normal leading-[1.2]">
                DON’T JUST BUY ANY INTERACTIVE FLAT PANEL
              </h1>
              <h2 className="font-medium text-center max-w-[830px] font-poppins text-3xl sm:text-4xl mt-8">
                Choose{" "}
                <span className="bg-[linear-gradient(277deg,_#7C51F5_29.46%,_#9671FF_72.75%)] bg-clip-text text-transparent">
                  COLTEC’s Intelligent Flat Panel{" "}
                </span>{" "}
                with the game-changing Classroom Platform for smarter learning!
              </h2>
            </div>
          </div>
          <div className="mt-[-400px] z-[-1]">
            <img
              alt="Coltec Landing"
              src="https://content.qeeb.in/coltec/landing/liner-blur-image.svg"
              className="w-full"
            />
          </div>
        </div>
        <div
          id="section1"
          className="flex justify-center flex-col items-center py-14"
        >
          <div>
            <img
              className="lg:w-[800px] h-[500px]"
              src="https://content.qeeb.in/coltec/landing/rise-flat-panel.webp"
              alt="Interactive Flat Panel"
            />
          </div>
          <div className="mt-8 p-8 lg:w-[800px] rounded-[24px] border-2 border-[#9771FF] bg-[#FBF8FF] shadow-[6px_6px_24px_0px_rgba(49,_34,_89,_0.15)]">
            <div className="grid grid-cols-3 gap-8">
              <div className="flex justify-start items-center space-x-3">
                <div>
                  <img
                    alt="Paper Touch"
                    src="https://content.qeeb.in/coltec/landing/paper-touch.svg"
                    className="h-7"
                  />
                </div>
                <div>
                  <h3 className="text-sm font-medium leading-3">Paper</h3>
                  <span className="text-xs">Touch technology</span>
                </div>
              </div>
              <div className="flex justify-start items-center space-x-3">
                <div>
                  <img
                    alt="Octa Core"
                    src="https://content.qeeb.in/coltec/landing/octa-core.svg"
                    className="h-7"
                  />
                </div>
                <div>
                  <h3 className="text-sm font-medium leading-3">Octa-Core</h3>
                  <span className="text-xs">Processor</span>
                </div>
              </div>
              <div className="flex justify-start items-center space-x-3">
                <div>
                  <img
                    alt="Dual"
                    src="https://content.qeeb.in/coltec/landing/dual-speaker.svg"
                    className="h-6"
                  />
                </div>
                <div>
                  <h3 className="text-sm font-medium leading-3">Dual</h3>
                  <span className="text-xs">Stereo speakers</span>
                </div>
              </div>
              <div className="flex justify-start items-center space-x-3">
                <div>
                  <img
                    alt="4K UHD"
                    src="https://content.qeeb.in/coltec/landing/4k-uhd.svg"
                    className="h-7"
                  />
                </div>
                <div>
                  <h3 className="text-sm font-medium leading-3">4K UHD</h3>
                  <span className="text-xs">Display</span>
                </div>
              </div>
              <div className="flex justify-start items-center space-x-3">
                <div>
                  <img
                    alt="Toughened"
                    src="https://content.qeeb.in/coltec/landing/tough-glass.svg"
                    className="h-7"
                  />
                </div>
                <div>
                  <h3 className="text-sm font-medium leading-3">Toughened</h3>
                  <span className="text-xs">Glass Screen</span>
                </div>
              </div>
              <div className="flex justify-start items-center space-x-3">
                <div>
                  <img
                    alt="Android 14"
                    src="https://content.qeeb.in/coltec/landing/android.svg"
                    className="h-7"
                  />
                </div>
                <div>
                  <h3 className="text-sm font-medium leading-3">Android 14</h3>
                  <span className="text-xs">Google Certified</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="section2" className="flex flex-col items-center py-14">
          <div className="flex justify-center items-start space-x-6 w-full px-10">
            {/* Left Side - Top Card */}
            <div className="flex flex-col space-y-6 w-1/4">
              <div className="p-6 rounded-[24px] border-2 border-[#9771FF] bg-[#FBF8FF] shadow-[6px_6px_24px_0px_rgba(49,_34,_89,_0.15)] flex flex-col items-center space-y-2">
                <img
                  alt="Dual Operating Systems"
                  src="https://content.qeeb.in/coltec/landing/dual-ops.svg"
                  className="h-12"
                />
                <h3 className="text-center font-medium text-lg">
                  Dual Operating Systems
                </h3>
                <span className="text-xs text-center">
                  Seamlessly switch between Windows and Android
                </span>
              </div>

              {/* Left Side - Bottom Card */}
              <div className="p-6 rounded-[24px] border-2 border-[#9771FF] bg-[#FBF8FF] shadow-[6px_6px_24px_0px_rgba(49,_34,_89,_0.15)] flex flex-col items-center space-y-2">
                <img
                  alt="Clear Communication"
                  src="https://content.qeeb.in/coltec/landing/clear-comm.svg"
                  className="h-12"
                />
                <h3 className="text-center font-medium text-lg">
                  Clear Communication
                </h3>
                <span className="text-xs text-center">
                  Enhance collaboration with seamless brainstorming
                </span>
              </div>
            </div>

            {/* Center - Image */}
            <div className="flex justify-center items-center w-1/2">
              <img
                className="lg:w-[800px] h-[500px] object-contain"
                src="https://content.qeeb.in/coltec/landing/rise-flat-panel.webp"
                alt="Interactive Flat Panel"
              />
            </div>

            {/* Right Side - Top Card */}
            <div className="flex flex-col space-y-6 w-1/4">
              <div className="p-6 rounded-[24px] border-2 border-[#9771FF] bg-[#FBF8FF] shadow-[6px_6px_24px_0px_rgba(49,_34,_89,_0.15)] flex flex-col items-center space-y-2">
                <img
                  alt="Ultra-Clear 4K Display"
                  src="https://content.qeeb.in/coltec/landing/4k-display.svg"
                  className="h-12"
                />
                <h3 className="text-center font-medium text-lg">
                  Ultra-Clear 4K Display
                </h3>
                <span className="text-xs text-center">
                  450 nits brightness with stunning clarity
                </span>
              </div>

              {/* Right Side - Bottom Card */}
              <div className="p-6 rounded-[24px] border-2 border-[#9771FF] bg-[#FBF8FF] shadow-[6px_6px_24px_0px_rgba(49,_34,_89,_0.15)] flex flex-col items-center space-y-2">
                <img
                  alt="Anti-Glare Display"
                  src="https://content.qeeb.in/coltec/landing/anti-glare.svg"
                  className="h-12"
                />
                <h3 className="text-center font-medium text-lg">
                  Anti-Glare Display
                </h3>
                <span className="text-xs text-center">
                  Clear visibility in any lighting conditions
                </span>
              </div>
            </div>
          </div>

          {/* Bottom Cards */}
          <div className="flex justify-center space-x-6 w-full px-10 mt-12">
            <div className="p-6 rounded-[24px] border-2 border-[#9771FF] bg-[#FBF8FF] shadow-[6px_6px_24px_0px_rgba(49,_34,_89,_0.15)] flex flex-col items-center space-y-2 w-1/3">
              <img
                alt="Toughened Glass"
                src="https://content.qeeb.in/coltec/landing/tough-glass.svg"
                className="h-12"
              />
              <h3 className="text-center font-medium text-lg">
                Toughened Glass
              </h3>
              <span className="text-xs text-center">
                Durable for classroom environments
              </span>
            </div>

            <div className="p-6 rounded-[24px] border-2 border-[#9771FF] bg-[#FBF8FF] shadow-[6px_6px_24px_0px_rgba(49,_34,_89,_0.15)] flex flex-col items-center space-y-2 w-1/3">
              <img
                alt="Android 14"
                src="https://content.qeeb.in/coltec/landing/android.svg"
                className="h-12"
              />
              <h3 className="text-center font-medium text-lg">Android 14</h3>
              <span className="text-xs text-center">
                Google Certified for optimal performance
              </span>
            </div>
          </div>
        </div>

        <div id="section3" className="flex flex-col items-center py-14">
          <div className="flex justify-center items-start space-x-6 w-full px-10">
            {/* Left Side - Top Card */}
            <div className="flex flex-col space-y-6 w-1/4">
              <div className="p-6 rounded-[24px] border-2 border-[#9771FF] bg-[#FBF8FF] shadow-[6px_6px_24px_0px_rgba(49,_34,_89,_0.15)] flex flex-col items-center space-y-2">
                <img
                  alt="Dual Operating Systems"
                  src="https://content.qeeb.in/coltec/landing/dual-ops.svg"
                  className="h-12"
                />
                <h3 className="text-center font-medium text-lg">
                  Dual Operating Systems
                </h3>
                <span className="text-xs text-center">
                  Seamlessly switch between Windows and Android
                </span>
              </div>

              {/* Left Side - Bottom Card */}
              <div className="p-6 rounded-[24px] border-2 border-[#9771FF] bg-[#FBF8FF] shadow-[6px_6px_24px_0px_rgba(49,_34,_89,_0.15)] flex flex-col items-center space-y-2">
                <img
                  alt="Clear Communication"
                  src="https://content.qeeb.in/coltec/landing/clear-comm.svg"
                  className="h-12"
                />
                <h3 className="text-center font-medium text-lg">
                  Clear Communication
                </h3>
                <span className="text-xs text-center">
                  Enhance collaboration with seamless brainstorming
                </span>
              </div>
            </div>

            {/* Center - Image */}
            <div className="flex justify-center items-center w-1/2">
              <img
                className="lg:w-[800px] h-[500px] object-contain"
                src="https://content.qeeb.in/coltec/landing/rise-flat-panel.webp"
                alt="Interactive Flat Panel"
              />
            </div>

            {/* Right Side - Top Card */}
            <div className="flex flex-col space-y-6 w-1/4">
              <div className="p-6 rounded-[24px] border-2 border-[#9771FF] bg-[#FBF8FF] shadow-[6px_6px_24px_0px_rgba(49,_34,_89,_0.15)] flex flex-col items-center space-y-2">
                <img
                  alt="Ultra-Clear 4K Display"
                  src="https://content.qeeb.in/coltec/landing/4k-display.svg"
                  className="h-12"
                />
                <h3 className="text-center font-medium text-lg">
                  Ultra-Clear 4K Display
                </h3>
                <span className="text-xs text-center">
                  450 nits brightness with stunning clarity
                </span>
              </div>

              {/* Right Side - Bottom Card */}
              <div className="p-6 rounded-[24px] border-2 border-[#9771FF] bg-[#FBF8FF] shadow-[6px_6px_24px_0px_rgba(49,_34,_89,_0.15)] flex flex-col items-center space-y-2">
                <img
                  alt="Anti-Glare Display"
                  src="https://content.qeeb.in/coltec/landing/anti-glare.svg"
                  className="h-12"
                />
                <h3 className="text-center font-medium text-lg">
                  Anti-Glare Display
                </h3>
                <span className="text-xs text-center">
                  Clear visibility in any lighting conditions
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Floating Scroll Button */}
        <div
          onClick={scrollToNextSection}
          className="fixed bottom-10 left-1/2 w-12 h-12 bg-[#7C51F5] p-2.5 animate-bounce flex justify-center items-center rounded-full cursor-pointer shadow-lg"
        >
          <img
            src="https://content.qeeb.in/coltec/landing/down-arrow.svg"
            alt="Down Arrow"
            className="h-8 pt-[5px]"
          />{" "}
          {/* Arrow down symbol */}
        </div>
        <div className="max-w-6xl mx-auto [&_.landing-footer]:p-20 [&_.bg-gray-950]:rounded-[30px] pb-16">
        <Footer  />
        </div>
        <ScrollToTopButton />
        <WhatsAppMsg />
      </div>
    </>
  );
};

export default InteractiveFlatPanel;
